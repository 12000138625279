import React from "react";
import brochure from "../assets/brochure.pdf";

const Footer = () => {
	return (
		<footer id="footer" className="footer">
			<div className="footer-legal text-center">
				<div className="container d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center">
					<div className="d-flex flex-column align-items-left align-items-lg-start">
						<div className="copyright">
						<strong> &copy; 2024{" "}
						</strong>
								<span>MBR Tech Solutions PVT. LTD</span>
							
							. All Rights Reserved.
						</div>
					</div>

					<div className="d-flex flex-column align-items-right align-items-lg-start">
						<div className="copyright">
							<strong>Developed By - </strong>
							Team of MBR Tech Solutions PVT. LTD.
						</div>
					</div>

					{/* <div className="order-first order-lg-last mb-3 mb-lg-0">
						<a href={brochure} className="download-brochure" download>
							<i className="bi bi-download" style={{ marginRight: "5px" }}></i> Get Brochure
						</a>
					</div> */}
				</div>
			</div>
		</footer>
	);
};

export default Footer;
