import { grep } from "jquery";

const Header = () => {
	return (
		<header id="header" className="header fixed-top">
			<div className="container-fluid align-items-center text-center" style={{backgroundColor:"#348e37"}}>
				<p style={{color:"whitesmoke",fontSize:"14px",fontFamily:"revert"}}>AN ISO 9001 : 2015 & ISO 27001 : 2013 CERTIFIED COMPANY</p>
			</div>
			<div className="container-fluid d-flex align-items-center justify-content-between px-lg-5">
				<a href="index.html" className="logo d-flex align-items-center scrollto me-auto me-lg-0">
					<img src="assets/img/MBR_SVG.svg" alt="Logo" />
				</a>

				<nav id="navbar" className="navbar">
					<ul>
						<li>
							<a className="nav-link scrollto" href="index.html#home">
								Home
							</a>
						</li>
						<li>
							<a className="nav-link scrollto" href="index.html#about">
								About Us
							</a>
						</li>
						<li>
							<a className="nav-link scrollto" href="index.html#services">
								Our Services
							</a>
						</li>
						<li>
							<a className="nav-link scrollto" href="index.html#clients">
								Our Client
							</a>
						</li>
						<li>
							<a className="nav-link scrollto" href="index.html#products">
								Our Product
							</a>
						</li>
						<li>
							<a className="nav-link scrollto" href="index.html#contact">
								Contact Us
							</a>
						</li>
						<li>
							<a href="http://103.82.145.98:81/dolibarr/public/ticket/index.php">
								Support
							</a>
						</li>
					</ul>
					<i className="bi bi-list mobile-nav-toggle d-none"></i>
				</nav>
			</div>
		</header>
	);
};

export default Header;
