const Home = () => {
	return (
		<section id="home" className="hero-animated d-flex align-items-center">
			<div className="container d-flex flex-column justify-content-center align-items-center text-center position-relative" data-aos="zoom-out">
				{/* <img src="assets/img/hero-carousel/hero-carousel-1.svg" className="img-fluid animated" alt="img here" /> */}
				<img src="assets/img/hero-carousel/hero-carousel-1.svg" className="img-fluid animated" alt="img here" />
				<h2>
					Welcome To <span>MBR Tech Solutions PVT. LTD.</span>
				</h2>
				<p>We Help You To Full-Fill Your Technology Need.</p>
			</div>
		</section>


	);
};

export default Home;
